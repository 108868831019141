<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-6">
      <div class="col-span-1 flex items-center">
        <h2 class="text-2xl font-bold">Bills Transactions</h2>
      </div>
      <div class="col-span-1 flex gap-1">
        <select
          name=""
          id=""
          class="border border-solid border-blue-200 rounded-sm placeholder-blue-300 outline-none py-3 text-13 text-gray-600 w-[50px] px-1"
          v-model="bills.selectFilter"
        >
          <option value="">No Filter</option>
          <option value="status">By status</option>
          <option value="serviceCategory">By service category</option>

          <option value="walletType">By walletType</option>
          <option value="reference">By Reference</option>
          <option value="userId">By UserId</option>
        </select>

        <search-form
          v-model="bills.queryValue"
          @submit="(event) => $refs.table.loadAjaxData()"
          v-if="bills.selectFilter"
        />
      </div>
    </div>

    <datatable
      :index="true"
      :ajax="true"
      :onClick="this.userProfile"
      :ajaxPagination="true"
      :url="bills.url"
      :columns="bills.columns"
      :selectFilter="bills.selectFilter"
      :queryValue="bills.queryValue"
      ref="table"
      :sorted="true"
      :dont_sort="bills.dont_sort"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      bills: this.$options.resource(null, {
        url: `${this.$baseurl}/bills/admin`,

        selectFilter: '',
        queryValue: '',

        dont_sort: ['user', 'request', 'status'],
        columns: [
          {
            th: 'USER',
            name: 'user',
            render: (transaction) => {
              return `${transaction.user.firstName} ${transaction.user.lastName}`;
            },
          },
          {
            th: 'BENEFICIARY',
            name: 'beneficiary',
            render: (transaction) => transaction.beneficiary || '---',
          },
          {
            th: 'SERVICE CATEGORY',
            name: 'serviceCategory',
            render: (transaction) => transaction.serviceCategory,
          },
          {
            th: 'SERVICE TYPE',
            name: 'request',
            render: (transaction) => transaction.request.serviceType,
          },
          {
            th: 'Amount',
            name: 'amount',
            render: (transaction) => transaction.request.amount,
          },
          {
            th: 'WALLET TYPE',
            name: 'walletType',
            render: (transaction) => transaction.walletType,
          },
          {
            th: 'Status',
            name: 'status',
            render: (transaction, status) => {
              switch (status) {
                case 'success':
                  return '<div class="badge badge-green-soft-outline">Success</div>';
                case 'failed':
                  return '<div class="badge badge-red-soft-outline">Failed</div>';
                case 'reversed':
                  return '<div class="badge badge-orange-soft-outline">Failed</div>';
                default:
                  return status;
              }
            },
          },

          {
            th: 'Status',
            name: 'status',
            render: (transaction, status) => {
              switch (status) {
                case 'success':
                  return '<div class="badge badge-green-soft-outline">Success</div>';
                case 'failed':
                  return '<div class="badge badge-red-soft-outline">Failed</div>';
                case 'reversed':
                  return '<div class="badge badge-orange-soft-outline">Failed</div>';
                default:
                  return status;
              }
            },
          },
          {
            th: 'Date',
            name: 'createdAt',
            render: (transaction) =>
              this.$options.filters.dateFormat(
                transaction.createdAt,
                'D, dd M Y, h:ia'
              ),
          },
        ],
      }),
    };
  },
  methods: {
    userProfile({ user }) {
      return this.$router.push({
        name: 'staff-view',
        params: {
          staffId: user?.userId,
        },
      });
    },
  },

  watch: {
    // Watch for changes in selectFilter
    'bills.selectFilter'(newValue) {
      if (!newValue) {
        this.bills.queryValue = ''; // Reset queryValue if no filter is selected
      }
    },
  },
};
</script>
